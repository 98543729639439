<script lang="ts" setup>
const props = defineProps<{
  activeIndex?: number
}>()
const activeIndex = ref(props.activeIndex ?? -1)

provide('activeIndex', activeIndex)
</script>

<template>
  <ul class="accordion cul">
    <slot />
  </ul>
</template>
